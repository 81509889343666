.NavbarItems{
   display: flex;
   justify-content: space-between;
   align-items: center;
   box-shadow: 0 5px 15px 0 rgba(0,0,0,0.25);
   width:100vw;
   height: 22vh;
   position: fixed; 
   left:50%;
   flex-direction: row;
   transform: translate(-50%);
   border-radius: 13px; 
   z-index:9999;
   background-color:#ffd5df;
   margin-left: 2px;
    
}

.name{
    display: none;
}
.ic{
    display: inline;
    padding-right: 2px;
}

.another{
   background-color: #d72c4f;
   border-radius: 13px; 
   
    flex-direction: row;
     justify-content:flex-end ;
    align-items: flex-start; 
    position: fixed;
   box-shadow: 0 0px 15px 0 rgba(0,0,0,0.25);
   width:96vw;
   height: 56px; 
   margin-top: -9px;
   align-items: center;
   left:50%;
   transform: translate(-50%);

   border-radius: 13px; 
   z-index:9999;
   display: flex;
   justify-content:right;
   gap:30px;
   margin-top:-14px;
 
}
 .together{
    display: flex;
    flex-direction: column;
    margin-top: 0%;
    margin-left: 2%;
    margin-right: 5%;
} 
img.women{
    height: 4.5rem;
    margin-top: 58%;
    margin-left: 50%;
}
.insta{
    width:2.5rem;
    display: flex;
    height: 2.5 rem;
    flex-direction: row;
}
.insti{
    width: 5.5em;
    height: 3.3em;
    border-radius: 13%;
}

.navbar-logo{
    font-size: 2rem;
    font-family: 'Diphylleia', serif;
    color:#d72c4f;
    margin-top: 50px;
    margin-left:0px;
    display: inline;

}
.nav-menu{
    display: grid;
    grid-template-columns: repeat(7,auto);
    grid-gap:0.5px;
    list-style: none;
    align-items: center;
    margin-top: 50px;

}
.Route{
    text-decoration: none;
    color:#d72c4f;
    font-size: 1.2rem;
    font-weight: 600;
    padding:0.7rem;
    white-space:nowrap;
}
.nav-links i{
    padding-right: 10px;
    display: none;
}
.Route:hover{
    background-color: #8A2BE2;
    color: #fff !important;;
    border-radius: 4px;   
    transition: all 0.2s ease-in-out;
} 
.insta:hover.overlay {
    opacity: 0.2;
  }
  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: #008CBA;
  }  
  
.nav-links-mobile{
   display: none; 
}
button{
    padding: 0.5rem 1rem;
    white-space: nowrap;
    border-radius: 0.3rem;
    font-size: 1rem;
    font-weight: 600;
    border:none;
    cursor: pointer;
    transition: 0.2;
}
/* button:hover{
    background-color: #151516;
    color: #fff;

} */
.menu-items{
    display: none;
}

    

@media screen and  (max-width:850px){
    .myhome{
        margin-left: -122px;
    }
    
    .navbar-logo{
        margin-left: 6vw;
        font-size: 3vw;
    }
    .navbar-logo{
        font-size: 1.5rem;}
    .i{
        margin-right: 100vw;
    }
    .together{
        margin-top: 0px;
    
    }
    .another{
        margin-top: -9px;
        padding:3px;
        width:100vw;
        margin-left: 0px;
    }
    .NavbarItems{
        z-index: 99;
        width:104vw;
        height:20vh;
    }
    .nav-menu{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        background-color:#fff;
        width: 80%;
        height:auto;
        
        position: absolute;
        top: 0;
    
        opacity:0;
        margin-right: 0%;
        align-items: stretch;
        padding:10px 0 0px 0;
        margin-top:-40px;
        margin-left: -75px;
       
    }
    .insta{
        width:32px;
        display: flex;
        height: 32px;
        
    }
    .nav-menu.active{
left:110px;
top: 127px;;
opacity:1;
z-index:-1;
transition: 0.3s ease-in-out;
border-radius: 13px;
margin-right: 0%;
fill-opacity: 1px;
text-align: center;
    }
    .nav-links{
        display: block;
        margin: auto;
        width:100px;
        font-size: 1.2rem;
        padding: 0.9rem 0;
        text-align: center;
    }  
    .nav-links:hover{
        /* background-color:#01959a  ; */
        transition: none;
     
        border-radius: 13px;
        width: 50%;
    }
    button{
       display: none; 
    }
    .nav-links-mobile{
    
        text-align: center;
        padding:1.5rem;
        margin: auto;
        border-radius: 4px;
        width:80%;
        background-color: #01959a;
        text-decoration: none;
        color: #fff;
        font-size: 1.2rem;
        font-weight: 700;
        letter-spacing: 2px;
     } 
     .menu-icons{
        display: block;
        cursor:pointer;
     }
     .menu-icons i{
        font-size: 1.2rem;
        color: #222;
    
     }
     .menu-items{
        display: block;
        cursor:pointer;
        margin:auto;
        margin-top: 70px;
        margin-right:40px;
     }
    
     .hide-item {
       transform: translateY(calc(-100% - var(--top)));
     }
     .mobile-menu-icon {
       display: block;
     }
     .navItems {
       --top: 1rem;
       transition: 0.3s ease transform;
       background-color: var(--deep-dark);
       padding: 2rem;
       width: 90%;
       max-width: 300px;
       border-radius: 12px;
       position: absolute;
       right: 1rem;
       top: var(--top);
       .closeNavIcon {
         display: block;
         width: 3rem;
         margin: 0 0 0 auto;
         cursor: pointer;
         * {
           pointer-events: none;
         }
       }
       li {
         display: block;
         margin-bottom: 1rem;
       }
    }   
}
.void{
    width: 0.1em;
    height: 130px;
}
.linked{
    width:60px;
}
a.navlink:hover{
  color: aliceblue;
background-color: cadetblue;
padding: 8em;
}

.products{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    justify-content: space-around;
   padding-top: 20em;
   margin-top: 100em;
  }
 
 .slider {
    position: relative;
    height: 80vh;
    display: flex;
margin-right: 0em;
    justify-content: center;
    align-items: center;
  }
  
  .image {
    width: 1900em;
    height: 500em;
    border-radius: 10em;
    margin-top: 270em;
    margin-right:40em;
    margin-left:200em;
  }
 
  
  .right-arrow {
    position: absolute;
    top: 70%;
    right: 40em;
    font-size: 3rem;
    color:white;
    z-index: 10;
    cursor: pointer;
    user-select: contain;
    opacity: 0.5;
  }
  .right-arrow:hover{
    opacity: 1;
  }
  .left-arrow:hover{
    opacity: 1;
  }
  
  .left-arrow {
    position: absolute;
    top: 70%;
    left: 220em;
    font-size: 3rem;
    color:white;
    z-index: 10;
    cursor: pointer;
    user-select: none;
    opacity: 0.5;
  }
   .hi{
    margin: auto;
  } 
  
  .slide {
    opacity: 0;
    transition-duration: 1s ease;
  }
  
  .slide.active {
    opacity: 1;
    transition-duration: 1s;
    transform: scale(1.08);
  }
  .Location{
    margin-top: 200em;
    width:90%;
    margin-left:auto;
    margin-right:auto;
    height: fit-content;
    border-radius: 10em;
  }
  .instcon {
    position: absolute;
    bottom: 0;
    left: 0;
    margin-top: 50%;
    width: 100%;
    height: 20px; /* adjust the height as needed */
    background-color: #ffd5df; /* match the background color of the navbar */
    padding: 5px;
    text-align: center;
    font-size: 9rem;
    font-weight: 600;
    color: #050505;
    margin-top: 5px;
  }
  
  .instcon marquee {
    width: 100%;
    height: 120%;
    direction: left;
    behavior: scroll;
    scrollamount: 2;
  }

  /* .car{
    display: flex;
    position: relative;
    }  */

  
  @media screen and (max-width: 768em) {
    
    
  .car{
    display: flex;
    position: relative;
    flex-direction: column;
    margin: auto;
  }
.loh{
  width:100%;
  display: block;
  margin: auto;
  
  height:3vh;
  

}
.image{
  margin-left: 0em;
  margin-right: 0em;
}
.left-arrow{
  left: 2em;  
  }
  .right-arrow{
    right: 2em;
  } 
    
}

.Home_Scene{
  background-color:white;}
.home{
  background-color:rgb(237, 252, 203);
width:100vw;
height:30em;
margin-bottom:5em;
}
.BTC{
  margin:auto;
}
.bu{
  text-decoration: none;
  padding: 0.5em 0.5em 0.5em 0.5em;
  border-radius: 0.1em;
  background-color: #320a3c;
  color: thistle;
  text-align: center;
  margin: auto;
  margin-top: 0.5em; 
  width:20em;
}
.bu:hover{
  background-color: #681d6682;
  color: white;
  padding: 0.5em 0.5em 0.5em 0.5em;
  border-radius: 1em;
  margin: auto;
  margin-top: 0.5em; 
}
.hi:hover{
  color: #320a3c;

}
.hu{
  padding: 2em;
 
}
.ho{
  display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    
}
.pop{
  height:12px;
}

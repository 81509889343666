.body{
    background-color:violet !important;
}
.skin{
    background-color:rgb(215, 215, 173);
    height: 100%;
    padding: 20px;
}
.titles{
    text-align: center;
    padding-top: 100px;
    font-size: 15em;
    color: rgb(105, 105, 5);
    font-family: 'Times New Roman', Times, serif;
}
.ptitles{
    text-align: center;
    font-size: 10em;
    color: rgb(105, 105, 5);
    font-family: 'Times New Roman', Times, serif;
 
}
.pic{
    display: block;
    margin: auto;
    width: 900px;
}
.pic2{
    width: 100%;
}
@media screen and (max-width: 850px) {
    .titles{
        font-size: 8em;
    }
    .ptitles{
        font-size: 4em;
    }


}

.hair{
    
    background: #3da3d5;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    color: white;
  }
  
  .title {
    font-size: 50vw;
    text-align: center;
    font-family: "Bebas Neue", sans-serif;
    padding-top: 100px;
    color: azure;
}
.meme{
  height:5em;
}
.ptitle{
  font-size: 18vw;
  text-align: center;
  font-family: "Bebas Neue", sans-serif;
  padding-top: 50px;
  color: azure;
}
.ptitle:hover{
  transition: all 0.5s ease-in-out;
}
  
  h3 {
    padding-top: 0.25em;
  }
  
  .vertical-timeline-element-content {
    box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25),
      0 0.4em 1.25em 0 rgba(0, 0, 0, 0.15) !important;
    padding: 2em 3em !important;
    color: white;
  }
  
  .date {
    color: rgb(201, 251, 255);
  }
  
  #description {
    margin: 1.5em 0 2em 0;
  }
  
  .button {
    text-decoration: none;
    padding: 0.5em 1em;
    border-radius: 5px;
    color: white;
  }
  
  .workButton {
    background-color: #06d6a0;
  }
  
  .workButton:hover {
    background-color: #0ac593;
  }
  
  .schoolButton {
    background-color: #f9c74f;
  }
  
  .schoolButton:hover {
    background-color: #f3bc3c;
  }
  .myimg{
    width: 570px;
    height: 570px;
  }
  .prob{
    height: 250px;
    width: 400px;
  }
  @media screen and (max-width: 850px) {
    .vertical-timeline-element-date {
      display: block !important;
      float: none !important;
      color: rgb(44, 44, 44);
      margin-top: 1.5em;
    }
    .myimg{
      width: 570px;
      height: 370px;
    }
    .prob{
      height: 150px;
      width: 300px;
    }
    .nav-barlogo{
      
    }
  }
  @media screen and (max-width: 400px) {
    .vertical-timeline-element-date {
      display: block !important;
      float: none !important;
      color: rgb(44, 44, 44);
      margin-top: 1.5em;
    }
    .myimg{
      width: 570px;
      height: 170px;
    }
    .prob{
      height: 100px;
      width: 300px;
    }
  }
 
 
  .col{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    background-color: antiquewhite;
    padding: 60px;
    
  }
  .rob{
    color: brown;
    display: block;
    margin:auto
  }
  .matter{
    color: black;
  }
.Scene_Contact {
  margin: 0;
  display: grid;
  place-content: center;
  height: 100vh;
  background-image: url('https://img.freepik.com/free-vector/purple-pink-halftone-background-vector_53876-67278.jpg?w=740&t=st=1688204390~exp=1688204990~hmac=d2bcd05b12da60232b0235ebecce98ec0bbb15d2535a823fa56d6a7b91e64d5d') !important;
  height: 90vh;
}

.button-container {
  display: flex;
  justify-content: center;
  margin-top: 10em; /* for larger screens */
}

.b2 {
  padding: 20px;
  background-color: antiquewhite;
  width: auto;
}

.b2:hover {
  background-color: orange;
}

@media screen and (max-width: 850px) {
  .button-container {
    margin-top: 5em; /* for smaller screens */
  }
}